import MainLayout from "../MainLayout";
import CornerstoneTeams from "./CornerstoneTeams";
import TeamsSection from './TeamsSection'

function Teams () {
    return(
        <>
        <MainLayout>
            <TeamsSection/>
            <CornerstoneTeams/>
        </MainLayout>
        </>
    )
}

export default Teams;