import './GroupsSection.css'

function GroupsSection() {
    return (
        <>
            <div className="groupsParent">
                <div className="groupsContainer">
                        <div className="Subgroups">
                            <div className="SubSubgroups">
                                <h1>Boys 2 Men</h1>
                                <img src="./image00006 - Copy.jpeg" alt="couldnt display image" />
                            <p>Every boy needs a man to show him how to be a man and to guide him through the rough patches of life while growing in Christ. This is what the man's ministry wing of the church is about. These leaders gather to strategize how to lead better in the church, their homes, businesses, careers, and peers. They also create a man cave where it is safe to dispel the myth that men don't cry. </p>
                                <button><a target="_blank" href="https://chat.whatsapp.com/Fh0hm9KDu8M4Nvyu13Tslm" >Join now</a></button>
                            </div>
                            <div className="SubSubgroups">
                                <h1>Women of Worth</h1>
                                <img src="./image00017.jpeg" alt="couldnt display image" />
                            <p>A vibrant group of women from different age groups gathering to pour into one another while creating a safe space for each woman to be vulnerable, to learn and to grow. The women's ministry meets often to pray, worship, fellowship, empower one another to be solid pillars of the church and of course they never forget to have some fun while at it. </p>
                                <button><a target="_blank" href="https://chat.whatsapp.com/Llv4bVtVGk4KIaZhf9COR2" >Join now</a></button>
                            </div>
                            <div className="SubSubgroups">
                                <h1>Youth</h1>
                                <img src="./image00031.jpeg" alt="couldnt display image" />
                            <p>Tomorrow's leaders gather every Friday to grow in Christ through the teachings of the word, games, discussion, and lots of fun. If you want to change your tomorrow, you invest in your youth today. Our vibrant youth is making sure that tomorrow is in safe hands as they invest in their growth and development today while making sure it's not boring.</p>
                                <button><a target="_blank" href="https://chat.whatsapp.com/JeLq3EFsAV76xE7C7S7uHP" >Join now</a></button>
                            </div>
                            <div className="SubSubgroups">
                                <h1>Sunday School</h1>
                                <img src="./image00036.jpeg" alt="couldnt display image" />
                            <p>The babies of the church who are a true definition of, "be fruitful and multiply". A safe learning environment for kids to learn about God while being taken care of in safe environment as their parents enjoy the service. These young ones love being part of what happens in the main church so they always show up to contribute something, whether it is singing for Mother's Day or reciting a poem for Pastor's appreciation they will show up. The Sunday School Teachers are the best, very loving and patient with the little ones and will take them out on a picnic from time to time.</p>
                             {/* <button>JOIN NOW</button> */}
                            </div>
                        </div>
                     </div>
            </div>
        </>
    )
}

export default GroupsSection;
