import MainLayout from '../MainLayout';
import CommunityHeroSection from './CommunityHeroSection'
import GroupsSection from './GroupsSection'
function Community() {
    return (
        <>
            <MainLayout >
                <CommunityHeroSection />
                <GroupsSection/>
            </MainLayout>
        </>
    )
}

export default Community;