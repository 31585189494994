import Navbar from './homePage/Navbar';
import Footer from './homePage/Footer';

const MainLayout = ({ children }) => {
    return (
        <>
            <Navbar />
            {children}
            <Footer />
        </>      
    )
}

export default MainLayout;