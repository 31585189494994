// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parentExec {
    display: flex;
    justify-content: center;
    flex-direction:column;
    align-items:center;
    font-family: 'Manrope', sans-serif;
    padding-bottom: 5%;
}

    .parentExec .parentExecHeader {
        font-size: 50px;
    }
.gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width:65%;
    gap:40px;
    color: #832022;
}

.gridExecItem img {
    display: flex;
    width: 100%;
    height:355px;
   
}

@media only screen and (max-width:768px) {
    .gridContainer {
        grid-template-columns: repeat(1, 1fr);
        gap:40px;
    } 

    .gridExecItem img {
        height:255px;
       
    }
}

@media only screen and (min-width:768px) and (max-width:1280px) {
    .gridContainer {
        grid-template-columns: repeat(2, 1fr);
        gap:40px;
    }  
}
`, "",{"version":3,"sources":["webpack://./src/components/aboutUsPage/ExecutiveSection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,qBAAqB;IACrB,kBAAkB;IAClB,kCAAkC;IAClC,kBAAkB;AACtB;;IAEI;QACI,eAAe;IACnB;AACJ;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;IACT,QAAQ;IACR,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,WAAW;IACX,YAAY;;AAEhB;;AAEA;IACI;QACI,qCAAqC;QACrC,QAAQ;IACZ;;IAEA;QACI,YAAY;;IAEhB;AACJ;;AAEA;IACI;QACI,qCAAqC;QACrC,QAAQ;IACZ;AACJ","sourcesContent":[".parentExec {\n    display: flex;\n    justify-content: center;\n    flex-direction:column;\n    align-items:center;\n    font-family: 'Manrope', sans-serif;\n    padding-bottom: 5%;\n}\n\n    .parentExec .parentExecHeader {\n        font-size: 50px;\n    }\n.gridContainer {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    width:65%;\n    gap:40px;\n    color: #832022;\n}\n\n.gridExecItem img {\n    display: flex;\n    width: 100%;\n    height:355px;\n   \n}\n\n@media only screen and (max-width:768px) {\n    .gridContainer {\n        grid-template-columns: repeat(1, 1fr);\n        gap:40px;\n    } \n\n    .gridExecItem img {\n        height:255px;\n       \n    }\n}\n\n@media only screen and (min-width:768px) and (max-width:1280px) {\n    .gridContainer {\n        grid-template-columns: repeat(2, 1fr);\n        gap:40px;\n    }  \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
