import './AboutUsHeroSection.css'

function AboutUsHeroSection() {
    return (
        <>
            <header>
                <section className="hero">
                    <div className="aboutPastor">
                        <div className="pastorHeading">
                            <div><img src="./Pastor.jpg" alt="pastorimage" /></div>
                            <div><h1>ABOUT OUR PASTOR <br /><span>Pastor K Madzhie</span></h1></div>
                            
                        </div>
                 
                        <p>Pastor Khethani Madzhie is the senior pastor of Cornerstone FGC, a multiracial and multicultural
church based in Lindhaven Roodepoort. He started his ministry in his home church in Venda, which is
also a Full Gospel Church. After graduating from the Full Gospel Bible College, he served as a Youth
pastor then an assistant pastor at his home church. He later went on to serve as the senior pastor in
the Irene branch of Full Gospel Church. He joined Cornerstone as a senior pastor in 2020 during
lockdown. He started out with a handful of people and by God’s grace within two years the church
was able to fill up to its capacity.
<br/><br/>
Pastor K (as he is affectionately known) had ambitions to study medicine but had to drop out to
answer the call of God. One of his greatest passions is to see people’s lives changed and he believes
that sometimes we must do more than just preach to people but try and meet their immediate
needs. It is because of this conviction that he decided to start a soup kitchen which services a
neighbouring informal settlement weekly. He strongly believes that the greatest sermons are not the
ones we preach but the ones we live.
<br/><br/>
He has been described by many as, “love in action” because of how he makes sure to love each
person who walks through our doors. Some marvel at how one person manages to know so many
people by name, helps them discover their purpose and meet them at their point of need. It is
because of this that Gen Zs call him, “if follow me as a I follow Christ was a person”. He loves and
serves people just like Jesus did.
<br/><br/>
As Pastor K always says, “welcome to Cornerstone FGC, we have nothing else to give you but the
true gospel of Christ. We only preach Christ and him crucified” he truly stands by these words.
Everyone who has decided to come back after the first visit says the word and the love from the
pastor is what made them stay. Pastor Khethani’s greatest ambition with Cornerstone is to help
God’s people create an environment that’s conducive for God to work, not forced but organic. An
environment where souls get saved, people come and experience genuine love that’s not faked. A
place where leaders are spiritually mature, we have quality believers, and we are not just about
quantity. It is to this environment that he believes we will be able to attract and keep people.<br/>
</p>
                    </div>
                 </section>
            </header>
        </>
    )
}

export default AboutUsHeroSection;