import { Link, Outlet} from 'react-router-dom'
import React from 'react'
import './PastorSection.css'

function PastorSection() {
    return (
        <>
            <div className="container">
                <div className="parent">
                    <img src="./Pastor.jpg" alt="pastorImage" />
                    <div className="CommentSection">
                        <h1>About Our Pastor</h1>
                        <div class="pastorsComment">
                            <span class="material-symbols-outlined">
                                forum
                            </span>
                            <p>Pastor Khethani Madzhie is the senior pastor of Cornerstone FGC, a multiracial and multicultural church based in Lindhaven Roodepoort. He started his ministry in his home church in Venda, which is also a Full Gospel Church. After graduating from the Full Gospel Bible College, he served as a Youth pastor then an assistant pastor at his home church. He later went on to serve as the senior pastor in the Irene branch of Full Gospel Church.</p>
                        </div>
                        <Link to = '/aboutus'>
                            <button>Read more</button>
                            </Link>
                            <Outlet />
                    
                    </div>
                </div>
            </div>
                
         </>)

}

export default PastorSection;