// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backGroundConerstone img {
    width: 100%;
    opacity: 0.7;
    height: 650px;
    margin-top:96px;
}

@media only screen and (max-width:640px) {
    .backGroundConerstone img {
        width: 100%;
        height: 150px;
    }

}

@media only screen and (min-width:640px) and (max-width:768px) {
    .backGroundConerstone img {
        height: 350px;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px) {
    .backGroundConerstone img {
        height: 450px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/homePage/HeroSection.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,eAAe;AACnB;;AAEA;IACI;QACI,WAAW;QACX,aAAa;IACjB;;AAEJ;;AAEA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;AACJ","sourcesContent":[".backGroundConerstone img {\n    width: 100%;\n    opacity: 0.7;\n    height: 650px;\n    margin-top:96px;\n}\n\n@media only screen and (max-width:640px) {\n    .backGroundConerstone img {\n        width: 100%;\n        height: 150px;\n    }\n\n}\n\n@media only screen and (min-width:640px) and (max-width:768px) {\n    .backGroundConerstone img {\n        height: 350px;\n    }\n}\n\n@media only screen and (min-width:768px) and (max-width:1024px) {\n    .backGroundConerstone img {\n        height: 450px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
