// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.backGroundCommunity {
    background-image: linear-gradient(to bottom right, #832022, #756300);
    width: 100%;
    height: 500px;
    font-family: 'Manrope', sans-serif;
    text-align: center;
    padding-top: 90px;
    color: white;
}

    .backGroundCommunity p {
        font-size: 70px;
        padding-top:80px;
    }

@media only screen and (max-width:640px) {
    .backGroundCommunity p {
        font-size: 50px;
    }
    .backGroundCommunity{
        height: 400px;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/communityPage/CommunityHeroSection.css"],"names":[],"mappings":"AAAA;IACI,oEAAoE;IACpE,WAAW;IACX,aAAa;IACb,kCAAkC;IAClC,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;AAChB;;IAEI;QACI,eAAe;QACf,gBAAgB;IACpB;;AAEJ;IACI;QACI,eAAe;IACnB;IACA;QACI,aAAa;IACjB;AACJ","sourcesContent":[".backGroundCommunity {\n    background-image: linear-gradient(to bottom right, #832022, #756300);\n    width: 100%;\n    height: 500px;\n    font-family: 'Manrope', sans-serif;\n    text-align: center;\n    padding-top: 90px;\n    color: white;\n}\n\n    .backGroundCommunity p {\n        font-size: 70px;\n        padding-top:80px;\n    }\n\n@media only screen and (max-width:640px) {\n    .backGroundCommunity p {\n        font-size: 50px;\n    }\n    .backGroundCommunity{\n        height: 400px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
