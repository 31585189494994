import { React, useState, useEffect } from 'react';
import './Navbar.css';
import { FaBars, FaTimes, FaHome, FaInfoCircle, FaUsers, FaCamera,FaPhoneSquare, FaBullseye,FaUserPlus} from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Navbar() {

    const [visible, setVisible] = useState(true);

    const handleToggle = () => {
        setVisible((visible) => !visible);
    };

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setScreenWidth(window.innerWidth);
    };

    useEffect(() => {
        // Add event listener to update dimensions on window resize
        window.addEventListener('resize', handleResize);

        // Cleanup by removing event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);



    return (
        <>
            <div className="navContainer">
                <div className="navbar">
                    <div className="navbar-container">
                        <Link to="/">
                            <img className="nav-logo" src="./Logo.png" alt="Logo" />
                        </Link>
                        <div className="menu-icon">
                        <div className="navbar-menu">
                <div><a href='/' style={window.location.pathname === "/" ? { "color": "#756300" } : { }}><FaHome /><span>HOME</span></a></div>
                <div><a href="/aboutus" style={window.location.pathname === "/aboutus" ? { "color": "#756300" } : {}}><FaInfoCircle /><span>ABOUT</span></a></div>
                <div><a href="/community" style={window.location.pathname === "/community" ? { "color": "#756300" } : {}}><FaUsers /><span>COMMUNITY</span></a></div>
                <div><a href="/gallery" style={window.location.pathname === "/gallery" ? { "color": "#756300" } : {}}><FaCamera /><span>GALLERY</span></a></div>
                <div><a href="/Teams" style={window.location.pathname === "/Teams" ? { "color": "#756300" } : {}}><FaUserPlus /><span>TEAMS</span></a></div>
            
                {/*<div><a href="/contactus" style={window.location.pathname === "/contactus" ? { "color": "#756300" } : {}}><FaPhoneSquare /><span>CONTACT</span></a></div> */}    
            </div>
                        </div> 
                    </div>
                 </div>
            </div>
        </>
    )
}
export default Navbar;