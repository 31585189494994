import MainLayout from '../MainLayout';
import TabContent from './TabContent';
import ExecutiveSection from './ExecutiveSection';
import AboutUsHeroSection from './AboutUsHeroSection';

function AboutUs() {
    return (
        <>
            <MainLayout >
                <AboutUsHeroSection/>
                <TabContent />
                <ExecutiveSection/>
            </MainLayout>
        </>
    )
}

export default AboutUs;