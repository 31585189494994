import './ExecutiveSection.css';

function ExecutiveSection() {
    return (
        <>
            <div className="parentExec">
                <h2 className="parentExecHeader">Our Leaders</h2>
                <div className='gridContainer'>

                    <div className="gridExecItem">
                   <img src="./image00001.jpeg" alt="pastorImage"/>
                   
                   <h2>Pastor Khethani Madzhie</h2>
                   <p>Chairman</p>
                    </div>

                <div className="gridExecItem">
                <img src="./image00002.jpeg"  alt="pastorImage" />
                <h2>Mr J Ndlovu</h2>
                <p>Deputy Chairman</p>
               </div>
               
               <div className="gridExecItem">
               <img src="./image00003.jpeg"  alt="pastorImage" />
                <h2>Mr C Netshikweta</h2>
                <p>Treasurer</p>
                </div>

                <div className="gridExecItem">
                <img src="./image00004.jpeg" alt="pastorImage" />
                <h2>Mrs M Mazibuko</h2>
                <p>Secretary</p>
                 </div>

                 <div className="gridExecItem">
                <img src="./image00005.jpeg" alt="pastorImage" />
                <h2>Mrs Sarah-Lee Norkee</h2>
                <p>Vice Secretary</p>
                 </div>

                
                    </div>
                </div>


        </>
    )
}

export default ExecutiveSection;