// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 90px 0;
    
}

    .formContainer p {
        font-family: 'Manrope', sans-serif;
        font-size: 40px;
    }

    .contactForm {
        display: flex;
        flex-direction: column;
    }

        .contactForm .inputText {
            width: 550px;
            height: 50px;
            font-size: 20px;
            border: solid #832022;
            border-radius: 10px;
            background-color: #F7F7F7;
        }

         .button {
            width: 200px;
            height: 50px;
            background-color: #756300;
            font-family: 'Manrope', sans-serif;
            font-size: 20px;
            margin-top: 20px;
            color: white;
            border: none;
        }

        
`, "",{"version":3,"sources":["webpack://./src/components/contactUsPage/Form.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,eAAe;;AAEnB;;IAEI;QACI,kCAAkC;QAClC,eAAe;IACnB;;IAEA;QACI,aAAa;QACb,sBAAsB;IAC1B;;QAEI;YACI,YAAY;YACZ,YAAY;YACZ,eAAe;YACf,qBAAqB;YACrB,mBAAmB;YACnB,yBAAyB;QAC7B;;SAEC;YACG,YAAY;YACZ,YAAY;YACZ,yBAAyB;YACzB,kCAAkC;YAClC,eAAe;YACf,gBAAgB;YAChB,YAAY;YACZ,YAAY;QAChB","sourcesContent":[".formContainer {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    padding: 90px 0;\n    \n}\n\n    .formContainer p {\n        font-family: 'Manrope', sans-serif;\n        font-size: 40px;\n    }\n\n    .contactForm {\n        display: flex;\n        flex-direction: column;\n    }\n\n        .contactForm .inputText {\n            width: 550px;\n            height: 50px;\n            font-size: 20px;\n            border: solid #832022;\n            border-radius: 10px;\n            background-color: #F7F7F7;\n        }\n\n         .button {\n            width: 200px;\n            height: 50px;\n            background-color: #756300;\n            font-family: 'Manrope', sans-serif;\n            font-size: 20px;\n            margin-top: 20px;\n            color: white;\n            border: none;\n        }\n\n        \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
