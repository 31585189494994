import './App.css';
import Home from './components/homePage/Home';
import ContactUs from './components/contactUsPage/ContactUs';
import AboutUs from './components/aboutUsPage/AboutUs';
import AboutUsHeroSection from './components/aboutUsPage/AboutUsHeroSection'
import Gallery from './components/galleryPage/Gallery';
import Community from './components/communityPage/Community';
import Teams from './components/TeamsPage/Teams';
import { Routes, Route } from 'react-router-dom'
function App() {
    return (
        <>
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/contactus' element={<ContactUs />} />
                <Route path="/AboutUs" element={<AboutUs />} >
                    <Route path='/AboutUs/:AboutUsHeroSection' element={<AboutUsHeroSection/>}/>
                </Route>
              <Route path='/gallery' element={<Gallery />} /> 
                <Route path='/community' element={<Community />} />
                <Route path='/Teams' element={<Teams/>} />
            
            </Routes>
        </>
  );
}

export default App;
