import React from 'react'
import './Gallery.css'
import { Link } from 'react-router-dom'

function Gallery() {
    return (
        <>
            <div className="gridParentContainer">
            <h1>Our Gallery</h1>
                <div className="galleryContainer">
                    <div className="parentGrid">
                        <div className="galleryItem"><img src="./ColorYouth.jpg"/></div>
                        <div className="galleryItem"><img src="./YouthHike.jpg" /></div>
                        <div className="galleryItem"><img src="./PastorAndKids.jpg" /></div>
                        <div className="galleryItem"><img src="./ManOfChurch.png" /></div>
                        <div className="galleryItem"><img src="./Church.jpg" /></div>
                    </div>
                </div>
                <Link to={`/gallery`} >
                    <button className="grid-nav-button" >See more</button>
                </Link>
                </div> 
        </>

    )

}

export default Gallery;