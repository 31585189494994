import MainLayout from '../MainLayout';
import Form from './Form'

function ContactUs() {
    return (
        <>
            <MainLayout >
                <Form/>
            </MainLayout>
        </>
    )
}

export default ContactUs;