import React, { useState } from 'react';
import './TabContent.css';
import { Document } from 'react-pdf'

function TabContent() {

    const [activeTab, setActiveTab] = useState("tab1");
    
    return (
        <>
            <div className="tabParent">
                <div className="tabParentCover">
                    <div className="tab">
                        <button className="tablinks" onClick={() => setActiveTab("tab1")} style={activeTab === "tab1" ? { "backgroundColor": "#ccc" } : {}} >About Cornerstone FGC</button>
                        <button className="tablinks" onClick={() => setActiveTab("tab2")} >Our Vision & Mission</button>
                        <button className="tablinks" onClick={() => setActiveTab("tab3")} >About Full Gospel Church</button>
                        <button className="tablinks" onClick={() => setActiveTab("tab4")} >Our News Letter</button>
                    </div>
                    {activeTab === "tab1" ? tab1() :  activeTab === "tab2" ? tab2() : activeTab === "tab3" ? tab3( ): tab4()}
                </div>
            </div>
        </>
    )
}

function tab1() {
    return (
        <>
            <div className="tabcontent">
                <p>We are the Cornerstone Full Gospel Church of God. A church whose firm foundation is Jesus Christ, the Chief Cornerstone. The church has its roots in the Princess informal settlement area, although geographically challenged, the church thrived in hard conditions such as the high rate of theft which also involved the known illegal electrical connections. The church then moved to another vacant Full Gospel Church of God in 2018 just 2km away where we currently are, in Lindhaven. It is here the local assembly's name changed from Princess Worship Center Full Gospel Church of God which was led by Pastor E. Matlala to The Cornerstone Full Gospel Church of God which is now under the leadership of Pastor KD Madzhie. During Covid, the church lost a lot of its members, but God quickly restored His house to its former glory and beyond. We are a heathy growing church, Word based and sound in biblical doctrine. A church thriving in love, a place you can be proud to call home. "You also, as living stones, are being built up a spiritual house, a holy priesthood, to offer up spiritual sacrifices acceptable to God through Jesus Christ. Therefore, it is also contained in the Scripture. 'Behold, I lay in Zion a chief cornerstone, elect, precious, and he who believes in Him will by no means be put to shame.' Therefore, to you who believe, He is precious; but to those who [b]are disobedient, 'The stone which the builders rejected Has become the chief cornerstone.'" (1 Peter 2:5-7, NKJV)</p>
            </div>
        </>
    )

}
function tab2() {
    return (
        <>
            <div className="tabcontent">
                <p>
                    <h3>Mission</h3>
                    Engage the Lost<br/>
                    Establish in Faith<br />
                    Empower to Serve<br />
                    Fulfil the Great Commission <br />
                    <h3>Vision</h3>
                    To Know God and make Him known.<br />
                    To train believers to express their love for God in loving people, we are saved to serve.
                    
                </p>
            </div>
        </>
    )

}

function tab3() {
    return (
        <>
            <div className="tabcontent">
                <p>
                    In the year 1909 George Bowie came to South Africa as a missionary sent from the Bethal Pentecostal Assembly, under the leadership of Minnie Draper, Newark in New Jersey, U.S.A It was in the month of April 1910 that the Pentecostal Mission was started, and this Pentecostal Mission was to become The Full Gospel Church of God in Southern Africa, a bilingual Church.<br /><br />
                    A great work was being done amongst the, so called, Coloured Community. Since 1925 J F Rowlands, and later with his brother Alex at his side, initiated and led a mighty Evangelistic thrust which has continued to this day in the Indian Community For many years the White community had the oversight of the whole Church, and all the Departments were subject to the Executive Council in lrene. Since 1986 all the communities had their own Moderators and Executive Councils, with representation on the General Moderateur. This constituted 4 associations in one Church.<br /><br />
                    In 1951 a wonderful event took place, under the guidance of the Lord, when the Full Gospel Church amalgamated with the Church of God, Cleveland, U.S.A., which church is represented in 162 countries of the world by means of a World Missions Programme. The blessings and fellowship of our American brethren has made a wonderful contribution to the Full Gospel Church in Southern Africa. This amalgamation has been extremely beneficial, and we thank our brethren of the Church of God for their unfailing loyalty and love.<br /><br />
                    Negotiations for change were in progress for some 16 years and eventually it was agreed that those communities who so desired could unite to become one Integrated association within the Full Gospel Church of God, and in March 1990 the United Assemblies of the Full Gospel Church of God was constituted, consisting of the majority of the Black Community, the so called Coloured Community, the Indian Community, and a small group from the White Community. The Church now had 2 groups instead of 4, the lrene Assemblies and the United Assemblies. More than 7 years later, in October 1997, we had the joyous experience of the whole Full Gospel Church of God in Southern Africa uniting to become one structural organisation, for which we praise the Lord., thus sculpturally representing the Body of Christ.

                </p>
            </div>
        </>
    )

}

function tab4() {
    return (
        <>
            <div className="tabcontent">
                <p>
                    Section is under development
                </p>
            </div>
        </>
    )

}


export default TabContent;