import { FiHeart, FiMusic} from "react-icons/fi";
import {GiWheelbarrow} from "react-icons/gi";
import {GrMultimedia} from "react-icons/gr";
import {FaHeart,FaMusic,FaRegCalendarCheck,FaPrayingHands,FaInfoCircle} from "react-icons/fa";
import {MdPermMedia,MdHelp,MdAdminPanelSettings} from "react-icons/md";
import{BiDonateHeart} from "react-icons/bi"
import './CornerstoneTeams.css';

function CornerstoneTeams () {
    return(
        <>
        <div className="ConerstoneTeamsContainer">

<div className="ParentContainer">
        <div className="TeamsConatiner">
           <div className="TeamsIcon">< FaHeart/></div> 
            <h3 className="TeamsHeading">WELLNESS</h3>
           <p className="TeamsInfo">This team deals with issues of mental health,and anything that affects your spiritual, mental, and emotional health. We offer relevent professional assistant.wellness.</p>
           <button className="Teamsbtn"><a target="_blank" href="https://chat.whatsapp.com/DaOYVxXg8Rw6gCTppfZv8L" >Join team</a></button>
        </div>
        <div className="TeamsConatiner">
           <div className="TeamsIcon">< GiWheelbarrow/></div> 
            <h3 className="TeamsHeading">MAINTAINANCE</h3>
           <p className="TeamsInfo">This team deals with fixing whatever is broken in the church, cutting grass outside, making sure everything is working well for Sunday and basically all things manpower.</p>
           <button className="Teamsbtn"><a target="_blank" href="https://chat.whatsapp.com/J9oktkj4a6y3A2I9jKzDsG" >Join team</a></button>
        </div>
        <div className="TeamsConatiner">
           <div className="TeamsIcon">< MdPermMedia/></div> 
            <h3 className="TeamsHeading">MEDIA</h3>
           <p className="TeamsInfo">This team deals with the media and communications of the church. Things like recording live for our social media, updating our social media, producing the newsletter.</p>
           <button className="Teamsbtn"><a target="_blank" href="https://chat.whatsapp.com/KAzRPqwJHDFKCS6H2Up4IH" >Join team</a></button>
        </div>
        <div className="TeamsConatiner">
           <div className="TeamsIcon"><FaInfoCircle /></div>
            <h3 className="TeamsHeading">USHERS</h3>
           <p className="TeamsInfo">This team ensures a smooth running of the service by ensuring everyone knows where to sit and maintaining protocol.Ensuring all memebers and guest are greated warmly</p>
           <button className="Teamsbtn"><a target="_blank" href="https://chat.whatsapp.com/CytZ5ya6osD3tv2lR0ufkQ" >Join team</a></button>
        </div>
        <div className="TeamsConatiner">
           <div className="TeamsIcon">< FaMusic/></div> 
            <h3 className="TeamsHeading">WORSHIP</h3>
           <p className="TeamsInfo">The team that leads the congregation in true praise and worship under the guidance of the Holy Spirit."psalm:105-2" Sing Praises to him!Tell Of All His Wondrous Works</p>
           <button className="Teamsbtn"><a target="_blank" href="https://chat.whatsapp.com/BuKLUOeSnjS0Sjgl3zglDU" >Join team</a></button>
        </div>
        <div className="TeamsConatiner">
           <div className="TeamsIcon">< FaPrayingHands/></div> 
            <h3 className="TeamsHeading">WELFARE </h3>
           <p className="TeamsInfo">This team deals with the welfare kitchen where we feed people in Princess every Tuesday, and we also give out food parcels to families in the church that are in need. </p>
           <button className="Teamsbtn"><a target="_blank" href="https://chat.whatsapp.com/C4DD1IGi18T9jDGJcBJsqo" >Join team</a></button>
        </div>
        <div className="TeamsConatiner">
           <div className="TeamsIcon">< FaRegCalendarCheck/></div> 
            <h3 className="TeamsHeading">EVENTS </h3>
           <p className="TeamsInfo">This team is responsible for the planning,generating of event ideas,managing event logistics and ensuring that events run smoothly to the required standard and standards</p>
           <button className="Teamsbtn"><a target="_blank" href="https://chat.whatsapp.com/IRlmAtERZ0GLMde0TkZ1Ey" >Join team</a></button>
        </div>
        <div className="TeamsConatiner">
           <div className="TeamsIcon">< BiDonateHeart/></div> 
            <h3 className="TeamsHeading">BEREAVEMENT</h3>
           <p className="TeamsInfo">This team deals with visiting the bereaved family and arranging the church to help where they need help. It also arranges hospital visits when a member is in hospital or home visits</p>
           <button className="Teamsbtn"><a target="_blank" href="https://chat.whatsapp.com/CzWhmxuFjVl7gd6HjuqlQP" >Join team</a></button>
        </div>

       </div>
        </div>
       
        </>
    )
}

export default CornerstoneTeams;