// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gridParentContainer {
    text-align: center;
    font-family: 'Manrope', sans-serif;
    margin: 90px 0;
    
   
}

.galleryContainer {
    display: flex;
    justify-content: center;
    padding-top: 40px;
}

.parentGrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 300px 300px;
    width:65%;
    gap:15px;
}

.galleryItem {
    height:300px;
    border-radius:10px;
}

    .galleryItem img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
    }

        .galleryItem img:hover {
            transform: scale(0.95);
            cursor: pointer;
        }

.grid-nav-button {
    width: 150px;
    height: 40px;
    background-color: #756300;
    font-family: 'Manrope', sans-serif;
    font-size: 20px;
    padding: 0 15px;
    margin-top: 20px;
    color: white;
    border: none;
    cursor: pointer;
}

@media only screen and (max-width:640px) {
    .parentGrid {
        display: grid;
        grid-template-columns: auto;
    }
 }

 @media only screen and (min-width:640px) and (max-width:1250px) {
    .parentGrid {
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
 }

`, "",{"version":3,"sources":["webpack://./src/components/homePage/Gallery.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,kCAAkC;IAClC,cAAc;;;AAGlB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,+BAA+B;IAC/B,SAAS;IACT,QAAQ;AACZ;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;IAEI;QACI,WAAW;QACX,YAAY;QACZ,mBAAmB;IACvB;;QAEI;YACI,sBAAsB;YACtB,eAAe;QACnB;;AAER;IACI,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,kCAAkC;IAClC,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,YAAY;IACZ,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI;QACI,aAAa;QACb,2BAA2B;IAC/B;CACH;;CAEA;IACG;QACI,aAAa;QACb,oCAAoC;IACxC;CACH","sourcesContent":[".gridParentContainer {\n    text-align: center;\n    font-family: 'Manrope', sans-serif;\n    margin: 90px 0;\n    \n   \n}\n\n.galleryContainer {\n    display: flex;\n    justify-content: center;\n    padding-top: 40px;\n}\n\n.parentGrid {\n    display: grid;\n    grid-template-columns: repeat(3, 1fr);\n    grid-template-rows: 300px 300px;\n    width:65%;\n    gap:15px;\n}\n\n.galleryItem {\n    height:300px;\n    border-radius:10px;\n}\n\n    .galleryItem img {\n        width: 100%;\n        height: 100%;\n        border-radius: 10px;\n    }\n\n        .galleryItem img:hover {\n            transform: scale(0.95);\n            cursor: pointer;\n        }\n\n.grid-nav-button {\n    width: 150px;\n    height: 40px;\n    background-color: #756300;\n    font-family: 'Manrope', sans-serif;\n    font-size: 20px;\n    padding: 0 15px;\n    margin-top: 20px;\n    color: white;\n    border: none;\n    cursor: pointer;\n}\n\n@media only screen and (max-width:640px) {\n    .parentGrid {\n        display: grid;\n        grid-template-columns: auto;\n    }\n }\n\n @media only screen and (min-width:640px) and (max-width:1250px) {\n    .parentGrid {\n        display: grid;\n        grid-template-columns: repeat(2,1fr);\n    }\n }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
