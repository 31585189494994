import React from 'react'
import './AboutCornerstone.css'
import { Link } from 'react-router-dom'
function AboutCornerstone() {
    return (
        <>
            <div className="containerCornerstone">
                <div className="parent">
                    <div className="aboutSection">
                        <h1><span >Welcome to</span> Cornerstone Full Gospel Church</h1>
                        <div class="aboutInfo">
                            <p>We are the Cornerstone Full Gospel Church of God. A church whose firm foundation is Jesus Christ, the Chief Cornerstone. The church has its roots in the Princess informal settlement area, although geographically challenged, the church thrived in hard conditions such as the high rate of theft which also involved the known illegal electrical connections. The church then moved to another vacant Full Gospel Church of God in 2018 just 2km away where we currently are, in Lindhaven. It is here the local assembly's name changed from Princess Worship Center Full Gospel Church of God which was led by Pastor E. Matlala to The Cornerstone Full Gospel Church of God which is now under the leadership of Pastor KD Madzhie.</p>
                        </div>
                        <Link to={`/aboutus`} >
                            <button>Read more</button>
                        </Link>
 
    

                            
                </div>
            </div>
            </div>
                
         </>)

}

export default AboutCornerstone;