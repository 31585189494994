import React from 'react'
import './GridSection.css'
import { Link } from 'react-router-dom'

function GridSection() {
    return (
        <>
            <div className="gridParent">
                <div className="grid-container-background">
                    <div className="grid-container">
                        <Link to={`/aboutus`} style={{ textDecoration: 'none',color:'black' }}>
                        <div className="grid-item">
                            <span class="material-symbols-outlined">
                                target
                            </span>
                            <h4>Our Mission</h4>
                            <p>To Know God and make Him known</p>
                            </div>
                        </Link>
                        <a target="_blank"  href="https://www.youtube.com/@khethanidarrylmadzhie1348"  style={{ textDecoration: 'none', color: 'black' }}>
                        <div className="grid-item">
                            <span class="material-symbols-outlined">
                                video_library
                            </span>
                            <h4>Sermons</h4>
                            <p>Our ministers have a lot to say to our church and
                                visitors, every Sunday & more</p>
                            </div>
                        </a>
                        <Link to={`/`} style={{ textDecoration: 'none', color: 'black' }}>
                        <div className="grid-item">
                            <span class="material-symbols-outlined">
                                podcasts
                            </span>
                            <h4>Podcasts</h4>
                            <p>Our ministries have a lot to say through podcasts to play and listen to</p>
                            </div>
                        </Link>
                        <Link to={`/teams`} style={{ textDecoration: 'none', color: 'black' }}>
                            <div className="grid-item">
                                <span class="material-symbols-outlined">
                                    group_add
                                </span>
                                <h4>Join A Team</h4>
                                <p>Become a part of any community within our
                                    church and meet other members</p>
                            </div>
                        </Link>
                        
                    </div>
                </div> 
            </div>
        </>
    )

}

export default GridSection;