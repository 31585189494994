// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.parentGallery {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: 'Manrope', sans-serif;
    flex-direction: column;    
}

    .parentGallery h1 {
        margin: 150px 0;
        color: #832022;
    }

.gallerViewContainer {
    display: grid;
    align-items: center;
    justify-content: center;
    padding-bottom: 70px;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 20rem;
    width:65%;
    height: 65%;
    margin-top: -90px;
    gap: 10px;
}

        .gallerViewContainer img {
            border-radius: 10px;
            width: 100%;
            height:100%;
           
        }

@media only screen and (max-width:640px) {
    .gallerViewContainer {
        grid-template-columns: repeat(1, 1fr);
    }

}

@media only screen and (min-width:640px) and (max-width:1024px) {
    .gallerViewContainer {
        grid-template-columns: repeat(2, 1fr);
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/galleryPage/ImageViewerSection.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,kCAAkC;IAClC,sBAAsB;AAC1B;;IAEI;QACI,eAAe;QACf,cAAc;IAClB;;AAEJ;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,oBAAoB;IACpB,qCAAqC;IACrC,qBAAqB;IACrB,SAAS;IACT,WAAW;IACX,iBAAiB;IACjB,SAAS;AACb;;QAEQ;YACI,mBAAmB;YACnB,WAAW;YACX,WAAW;;QAEf;;AAER;IACI;QACI,qCAAqC;IACzC;;AAEJ;;AAEA;IACI;QACI,qCAAqC;IACzC;AACJ","sourcesContent":[".parentGallery {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    font-family: 'Manrope', sans-serif;\n    flex-direction: column;    \n}\n\n    .parentGallery h1 {\n        margin: 150px 0;\n        color: #832022;\n    }\n\n.gallerViewContainer {\n    display: grid;\n    align-items: center;\n    justify-content: center;\n    padding-bottom: 70px;\n    grid-template-columns: repeat(3, 1fr);\n    grid-auto-rows: 20rem;\n    width:65%;\n    height: 65%;\n    margin-top: -90px;\n    gap: 10px;\n}\n\n        .gallerViewContainer img {\n            border-radius: 10px;\n            width: 100%;\n            height:100%;\n           \n        }\n\n@media only screen and (max-width:640px) {\n    .gallerViewContainer {\n        grid-template-columns: repeat(1, 1fr);\n    }\n\n}\n\n@media only screen and (min-width:640px) and (max-width:1024px) {\n    .gallerViewContainer {\n        grid-template-columns: repeat(2, 1fr);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
