 
 function TeamsSection () {
    return(
      <>
       <div className="backGroundCommunity">
                <p>CORNERSTONE TEAMS</p>
                <em>"Unity is strength. . . when there is teamwork and collaboration, wonderful things can be achieved."</em>
            </div>
      </>
        
    )
 }

 export default TeamsSection;