import './CommunityHeroSection.css'
function CommunityHeroSection() {
    return (
        <>
            <div className="backGroundCommunity">
                <p>COMMUNITY GROUPS</p>
                <em>"Community Groups are the soil in which our spiritual roots grow deeper."</em>
            </div>
        </>
    )
}

export default CommunityHeroSection;