import './HeroSection.css'
function HeroSection() {
    return (
        <>
            <div className="backGroundConerstone">
                <img src="./conerstone.jpg" alt="Background" />
            </div>
        </>
    )
}

export default HeroSection;