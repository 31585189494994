import MainLayout from '../MainLayout';
import ImageViewerSection from './ImageViewerSection'

function Gallery() {
    return (
        <>
            <MainLayout >
                <ImageViewerSection/>
            </MainLayout>
        </>
    )
}

export default Gallery;