import React from 'react'
import'./Events.css'
import { FaClock ,FaHome} from 'react-icons/fa'





function Events() {
  
  return (
    <>
      <div className="EventsParent">
        <h1>UPCOMING EVENTS</h1>
        </div>
        <div className="EventsCountainer">
          <div className="EventsImage">
            <img src="./YouthEvents.jpg"/>
          </div>
          <div className="EventText">
          <h3>RELATIONSHIP REHAB</h3>
          <p>The Youth is inviting you to their inetryouth service<br/>
          That will be held 11:30 to 12:30 AM for the empowerment <br/>
          of the youth</p>
          </div>
          <div className="BorderLine">
            
          <div className="EventsDate&Time">
            <div className="TimeCover">
              <p>Sep</p>
              <h4 >15</h4> 
              <ul className="EventClock">
              <li><span><FaClock/></span>Mondays 12:30-1:30 PM</li>
              <li> <span><FaHome/></span>Online Zoom</li>
            </ul> 
            </div>
            </div>
            <div className='SlideArrows'>
            <a className="prev" onclick="plusSlides(-1)">&#10094;</a>
            <a className="next" onclick="plusSlides(1)">&#10095;</a>
            </div>
            <div className='SliderDots'>
            <span className="dot" onclick="currentSlide(1)"></span>
           <span className="dot" onclick="currentSlide(2)"></span>
           <span className="dot" onclick="currentSlide(3)"></span>
            </div>
            </div>
          </div>
          
        
        
          
          
          
  
    </>
  )
}

export default Events;

