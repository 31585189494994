import MainLayout from '../MainLayout';
import HeroSection from './HeroSection';
import AboutCornerstone from './AboutCornerstone';
import PastorSection from './PastorSection';
import GridSection from './GridSection';
import Gallery from './Gallery';
import Events from './Events';





const Home = () => {
    return (
        <>
            <MainLayout>
                <HeroSection />
                <AboutCornerstone/>
                <PastorSection />
                <GridSection />
                <Gallery />
            </MainLayout>
            
        </>
     
    )
}

export default Home;