import './Form.css'

function Form() {
    return (
        <>
            <div className="formContainer">
                <p>Mail Us</p>
                <form className="contactForm" action="mailto:maladzethendo@gmail.com" method="post">
                    <input className="inputText" type="text" id="yourName" name="names" placeholder=" Your Names" /><br />
                    <input className="inputText" type="text" id="email" name="email" placeholder=" Email" /><br />
                    <input className="inputText" type="text" id="phone" name="phone" placeholder=" Phone Number" /><br />
                    <textarea className="inputText" name="message" style={{ "width": "550px", "height": "200px" }} placeholder=" Your Message" />
                    <input className="button" type="submit" value="Send Message" />
                </form>  
                
            </div>

        </>
    )
}

export default Form;