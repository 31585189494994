import React from 'react'
import './Footer.css'
import { Link } from 'react-router-dom'

function Footer() {
    return (
        <>
            <footer>
                <div className="footerParent">
                    <div className="footerChild1">
                        <div className="map">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.697042222443!2d27.852602987024532!3d-26.141419302399775!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e959f6635320a67%3A0xf2e049c93403a72c!2sFULL%20GOSPEL%20CHURCH%20OF%20GOD%20THE%20CORNERSTONE%20LINDHAVEN!5e0!3m2!1sen!2sza!4v1687093483998!5m2!1sen!2sza"
                                width="100%"
                                height="600"
                                style={{ border: "0" }}
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade">
                            </iframe>
                        </div>
                        <div className="footerContactUs">
                            <h2><b>SERVICE TIMES</b></h2>
                            <p><b>Sunday</b> Intercession - 9:00 to 9:30, Main Service - 9:30</p>
                            <p><b>Wednesday</b> MidWeek Service - 18:00 to 19:00</p>
                            <p><b>Friday</b> Youth Service - 18:00 to 19:00</p>
                            <h2><b>BANK DETAILS</b></h2>
                            <p><b>Account name</b> Full Gospel Church Lindhaven</p>
                            <p><b>Bank</b> Absa</p>
                            <p><b>Account number</b> 4076866737</p>
                            <h2><b>CONTACT US</b></h2>
                            <p><b>South Africa</b>, Gauteng</p>
                            <p>249 South Rd, Lindhaven, Roodepoort, South Africa, 1724</p>
                            <p><b>Phone</b> <br />
                                <a href="tel:072 845 1221">072 845 1221</a></p>
                            <button ><a href="mailto:lindhavenfgc@gmail.com" >Send email</a></button>
                            
                        </div>
                    </div>
                    <div className="footerChild2">
                        <div className="socials">
                            <p>Full Gospel Church Conerstone &copy; 2023</p>
                            <p>Follow Us:
                              <a target="_blank" href="https://www.facebook.com/p/Lindhaven-Full-Gospel-Church-of-God-The-Cornerstone-100075869379287/">  <i class="fa fa-facebook-square" aria-hidden="true"></i></a>
                              <a target="_blank" href="https://www.instagram.com/fgc_cornerstonelindhaven/?hl=en"><i class="fa fa-instagram" aria-hidden="true"></i></a> 
                              <a > <i class="fa fa-google-plus-square" aria-hidden="true"></i></a>
                              <a > <i class="fa fa-twitter-square" aria-hidden="true"></i></a>
                                
                            </p>
                        </div>
                    </div>

                </div>
            </footer>
        </>
    )
}

export default Footer;