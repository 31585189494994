import React, { useState, useCallback } from 'react';
import ImageViewer from 'react-simple-image-viewer';
import './ImageViewerSection.css';

function ImageViewerSection() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const images = [
    "./ColorYouth.jpg",
    "./YouthHike.jpg",
      "./PastorAndKids.jpg",
      "./ManOfChurch.png",
      "./Church.jpg",
      "./image00006.jpeg",
      "./image00011.jpeg",
      "./image00010.jpeg",
      "./image00014.jpeg",
      "./image00023.jpeg",
      "./image00034.jpeg",
      "./boys2men.jpeg",
      "./communityImage.jpg"
  ];

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

    return (
        <>
            <div className="parentGallery">
                <h1>Our Gallery</h1>
                <div className="gallerViewContainer" style={isViewerOpen ? { zIndex: '20' } :{}}>
                    {images.map((src, index) => (
                        <img
                            src={src}
                            onClick={() => openImageViewer(index)}
                            width="100%"
                            key={index}
                            alt=""
                        />
                    ))}

                    {isViewerOpen && (
                        <ImageViewer
                            src={images}
                            currentIndex={currentImage}
                            onClose={closeImageViewer}
                            disableScroll={true}
                            backgroundStyle={{
                                backgroundColor: "rgba(0,0,0,0.85)"
                            }}
                            closeOnClickOutside={true}
                        />
                    )}
                </div>
            </div>
        </> 
  );
}

export default ImageViewerSection;