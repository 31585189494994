// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Style the tab */

.tabParent {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: 'Manrope', sans-serif;
    color: #832022
}
.tabParentCover {
    width: 65%;
}
.tab {
    border: 1px solid #ccc;
    background-color: #f1f1f1;
    border-bottom: none;
    margin-top: 90px;
    text-align: left;
}

    /* Style the buttons inside the tab */
    .tab button {
        /*  background-color: inherit;*/

        border: none;
        outline: none;
        cursor: pointer;
        padding: 14px 16px;
        transition: 0.3s;
        font-size: 20px;
        font-weight: bolder;
        color: #832022
    }

        /* Change background color of buttons on hover */
        .tab button:hover {
            background-color: #ddd;
        }

        /* Create an active/current tablink class */
        .tab button:focus {
            background-color: #ccc;
        }

/* Style the tab content */
.tabcontent {
    border: 1px solid #ccc;
    padding: 0 1.4%;

}

@media only screen and (max-width:640px) {
    .tab button {
        width: 100%;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/aboutUsPage/TabContent.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;IACI,oBAAoB;IACpB,WAAW;IACX,uBAAuB;IACvB,sBAAsB;IACtB,mBAAmB;IACnB,kCAAkC;IAClC;AACJ;AACA;IACI,UAAU;AACd;AACA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;AACpB;;IAEI,qCAAqC;IACrC;QACI,+BAA+B;;QAE/B,YAAY;QACZ,aAAa;QACb,eAAe;QACf,kBAAkB;QAClB,gBAAgB;QAChB,eAAe;QACf,mBAAmB;QACnB;IACJ;;QAEI,gDAAgD;QAChD;YACI,sBAAsB;QAC1B;;QAEA,2CAA2C;QAC3C;YACI,sBAAsB;QAC1B;;AAER,0BAA0B;AAC1B;IACI,sBAAsB;IACtB,eAAe;;AAEnB;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":["/* Style the tab */\n\n.tabParent {\n    display: inline-flex;\n    width: 100%;\n    justify-content: center;\n    flex-direction: column;\n    align-items: center;\n    font-family: 'Manrope', sans-serif;\n    color: #832022\n}\n.tabParentCover {\n    width: 65%;\n}\n.tab {\n    border: 1px solid #ccc;\n    background-color: #f1f1f1;\n    border-bottom: none;\n    margin-top: 90px;\n    text-align: left;\n}\n\n    /* Style the buttons inside the tab */\n    .tab button {\n        /*  background-color: inherit;*/\n\n        border: none;\n        outline: none;\n        cursor: pointer;\n        padding: 14px 16px;\n        transition: 0.3s;\n        font-size: 20px;\n        font-weight: bolder;\n        color: #832022\n    }\n\n        /* Change background color of buttons on hover */\n        .tab button:hover {\n            background-color: #ddd;\n        }\n\n        /* Create an active/current tablink class */\n        .tab button:focus {\n            background-color: #ccc;\n        }\n\n/* Style the tab content */\n.tabcontent {\n    border: 1px solid #ccc;\n    padding: 0 1.4%;\n\n}\n\n@media only screen and (max-width:640px) {\n    .tab button {\n        width: 100%;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
